<template>
    <h1 class="page-header label-indigo" >
    Search Locations
    </h1>
    <hr class="mb-4" />
    
    
    <div class="row">
    
    <div class="card">

    
    
    <div class="tab-content p-4">
    <div class="tab-pane fade show active" id="allTab">
    
    <div class="input-group mb-4">
    <div class="flex-fill position-relative">
    <div class="input-group">
    <input type="text" v-model="searchlocals" class="form-control ps-35px" placeholder="Search Locations" />
    <div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
    <i class="fa fa-search opacity-5"></i>
    </div>
    </div>
    </div>
    </div>
    
    
    <div class="table-responsive">
    <table class="table table-hover text-nowrap">
    <thead>
    <tr>
    <th class="border-top-0 pt-0 pb-2">#</th>
    <th class="border-top-0 pt-0 pb-2">Location Title</th>
    <th class="border-top-0 pt-0 pb-2">Designation</th>
    <th class="border-top-0 pt-0 pb-2">Reg Population</th>
    <th class="border-top-0 pt-0 pb-2">Action</th>
    </tr>
    </thead>
    <tbody v-if="searchlocations">
    <tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >
    
        <td class="align-middle">{{ index + 1}}. </td>
        <td class="align-middle">{{ item.title }}</td>
        <td class="align-middle" >
        <span class="badge btn btn-sm btn-dark" style="width:50%" v-if="item.country_id == null && item.district_id == null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">Country</span>
        <span class="badge btn btn-sm btn-primary" style="width:50%" v-if="item.country_id != null && item.district_id == null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">District</span>
        <span class="badge btn btn-sm btn-info" style="width:50%" v-if="item.country_id != null && item.district_id != null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">County</span>
        <span class="badge btn btn-sm btn-danger" style="width:50%" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id == null && item.parish_id == null && item.village_id == null">Sub County</span>
        <span class="badge btn btn-sm btn-success" style="width:50%" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id != null && item.parish_id == null && item.village_id == null">Parish</span>
        <span class="badge btn btn-sm btn-warning" style="width:50%" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id != null && item.parish_id != null && item.village_id == null">Village</span>
        </td>
        <td>
            {{ item.collections_count}}
        </td>
        <td class="align-middle">
      
            <router-link :to="{name:'countrydetails', params:{id:item.id}}" class="badge btn btn-sm btn-dark bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id == null && item.district_id == null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">View Details</router-link>


            <router-link :to="{name:'districtcounties', params:{id:item.id}}" class="badge btn btn-sm btn-primary bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id != null && item.district_id == null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">View Details</router-link>

            <router-link :to="{name:'countydivisions', params:{id:item.id}}" class="badge btn btn-sm btn-info bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id != null && item.district_id != null && item.county_id == null && item.division_id == null && item.parish_id == null && item.village_id == null">View Details</router-link>


            <router-link :to="{name:'divisionparishes', params:{id:item.id}}" class="badge btn btn-sm btn-danger bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id == null && item.parish_id == null && item.village_id == null">View Details</router-link>

            <router-link :to="{name:'parishvillages', params:{id:item.id}}" class="badge btn btn-sm btn-success bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id != null && item.parish_id == null && item.village_id == null">View Details</router-link>

            <router-link :to="{ name:''}" class="badge btn btn-sm btn-warning bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" v-if="item.country_id != null && item.district_id != null && item.county_id != null && item.division_id != null && item.parish_id != null && item.village_id == null">View Details</router-link>
       </td>
    
    </tr>
    
    
    
    
    </tbody>
    </table>
    </div>
    

    </div>
    </div>
    </div>
    
    
    
    </div>
    
    </template>
    <script>
    import {mapGetters, mapActions } from 'vuex'
    export default {
    
        data(){
            return{
                filtertype:'',
                searchlocals:''
            }
        },
    
    
    
        watch:{
            searchlocals(){
                if(this.searchlocals){
                    if(this.searchlocals.length > 2){
                    this.searchalllocations()
                    }
                }
            }
        },
    
    
    
         computed:{
        ...mapGetters(['msg', 'error','pageloader','loading', 'searchlocations']),

            filteredlist(){
                if(this.filtertype == 'active'){
                    return this.searchlocations.filter(co => co.status == 1)
                }else if(this.filtertype == 'suspended'){
                    return this.searchlocations.filter(co => co.status == 2)
                }else {
                    return this.searchlocations
                }
            },
        },
    
    
        methods:{
        ...mapActions(['searchgloballocations']),

        searchalllocations(){
            let data = {
            search:this.searchlocals
            }
            this.searchgloballocations(data).then((response)=>{
        }) 
        }


    },
    
        
 
    }
    </script>